<template>
  <b-col
    cols="12"
  >
    <b-card class="px-2 py-1">
      <b-row>
        <h2> Buscar documentos a financiar </h2>
      </b-row>
      <b-row>
        <p class="my-0">
          Sube tus documentos pendientes de pago y empieza a obtener la liquidez que tu negocio necesita.
        </p>
      </b-row>
      <b-row class="mt-2">
        <b-col
          md="4"
          class="px-0"
        >
          <b-form-group
            label="Seleccione tipo de documento"
            label-for="documentType"
          >
            <b-form-select
              id="documentType"
              v-model="documentType"
              :options="optionsDocumentType"
              name="documentType"
              :disabled="xmlRows.length > 0"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template v-if="documentType === '02'">
        <validation-observer ref="valSingleReceipt">
          <b-form
            enctype="multipart/form-data"
            @submit.prevent="registerSingleReceipt"
          >
            <b-row class="my-2 custom-card-efide">
              <b-col
                md="6"
              >
                <b-form-group
                  label="Selecciona la empresa pagadora*"
                  label-for="payingCompany"
                >
                  <b-form-select
                    id="payingCompany"
                    v-model="singleReceipt.payingCompany"
                    :options="optionsPayingCompanies"
                    required
                    name="payingCompany"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  label="RUC"
                  label-for="ruc"
                >
                  <b-form-input
                    id="ruc"
                    v-model="singleReceipt.ruc"
                    name="ruc"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Selecciona la moneda*"
                  label-for="currency"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Moneda"
                    rules="required"
                    vid="currency"
                  >
                    <b-form-select
                      id="currency"
                      v-model="singleReceipt.currency"
                      :options="optionsCurrency"
                      :state="errors.length > 0 ? false:null"
                      name="currency"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Importe del documento"
                  label-for="originalAmount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Importe del documento"
                    rules="required|money|min_value:0"
                    vid="originalAmount"
                  >
                    <b-form-input
                      id="originalAmount"
                      v-model="singleReceipt.originalAmount"
                      :state="errors.length > 0 ? false:null"
                      name="originalAmount"
                      :formatter="formatterMoney"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Fecha de pago"
                  label-for="payingDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Fecha de pago"
                    rules="required"
                    vid="payingDate"
                  >
                    <flat-pickr
                      id="payingDate"
                      v-model="singleReceipt.payingDate"
                      placeholder="dd/mm/aaaa"
                      name="payingDate"
                      :state="errors.length > 0 ? false:null"
                      :config="{ ...configDatePickr, minDate: today }"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Subir imagen o pdf del comprobante"
                  label-for="frontalImage"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Imagen o pdf del comprobante"
                    rules="required"
                    vid="frontalImage"
                  >
                    <b-form-file
                      id="frontalImage"
                      v-model="singleReceipt.frontalImage"
                      :state="errors.length > 0 ? false:null"
                      name="frontalImage"
                      accept="application/pdf,image/*"
                      placeholder="Ningún archivo elegido"
                      browse-text="Cargar archivo"
                      @change="onFrontalImageChange"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Observación"
                  label-for="observation"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Observación"
                    vid="observation"
                  >
                    <b-form-textarea
                      id="observation"
                      v-model="singleReceipt.observation"
                      :state="errors.length > 0 ? false:null"
                      name="observation"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-row class="mt-2 justify-content-end col-12">
                <b-col
                  md="5"
                >
                  <b-button
                    variant="primary"
                    type="submit"
                    block
                  >
                    Registrar solicitud
                  </b-button>
                </b-col>
              </b-row>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
      <template v-else-if="documentType === '01'">
        <b-row class="my-2 custom-card-efide">
          <b-col
            md="12"
          >
            <UploadDropImages
              ref="uploadDI"
              class="mt-2"
              :max="30"
              max-error="Número máximo de archivos excedido"
              file-type=".xml"
              upload-msg="Arrastra los archivos o haz click aquí para cargarlos"
              other-msg="(Debes apretar Cargar documentos para confirmar la carga de archivos.)"
              file-error="Solo se permiten archivos xml"
              clear-all="Eliminar todo"
              @changed="selectedFileDZ"
            />
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-end">
          <b-col
            md="3"
          >
            <b-button
              variant="primary"
              type="button"
              block
              :disabled="xmls.length === 0"
              @click="uploadXML"
            >
              Cargar documentos
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-card>
    <b-card
      v-if="documentType === '01'"
      class="px-2 py-1 mt-2"
    >
      <h2> Enviar solicitud de financiamiento </h2>
      <p> Para cada documento cargado debe incluir su <b> importe neto </b> y <b> fecha de recepción </b> manualmente. </p>
      <div
        v-if="xmlRows.length===0"
        class="d-flex justify-content-center col-12 mt-3 mb-2"
      >
        <h3>
          No hay contenido para mostrar
        </h3>
      </div>
      <vue-good-table
        v-else
        :columns="columns"
        :rows="xmlRows"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        class="mt-3 mb-2"
      >
        <div
          slot="emptystate"
          class="text-center my-5"
        >
          <h3
            v-if="xmlRows.length===0"
          >
            Aun no se han cargado facturas
          </h3>
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'duesData'">
            <!-- <span> {{ props.row.duesData.length }} cuota(s) </span> -->
            <span> {{ props.row.duesQuantity }} cuota(s) </span>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'actions'">
            <span>
              <b-button
                variant="warning"
                class="btn-icon mx-1"
                @click="showReceiptModal(props.row.documentNumber)"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                variant="danger"
                class="btn-icon mx-1"
                @click="deleteXML(props.row.documentNumber, props.row.originalname)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-center flex-wrap mt-1">
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </template>
      </vue-good-table>
      <b-row
        v-if="xmlRows.length > 0"
        class="d-flex justify-content-end"
      >
        <b-col
          md="3"
        >
          <b-button
            variant="primary"
            style="overflow-wrap: break-word; font-size: 14px;"
            type="button"
            block
            :disabled="processing"
            @click="createReceipts"
          >
            <b-spinner
              v-if="processing"
              style="margin-bottom: 2px"
              small
            />
            {{ processing ? 'Enviando...' : 'Enviar solicitud' }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <!-- MODAL -->
    <b-modal
      ref="modal-receipt"
      v-model="showModal"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <div class="modal-receipt__header">
        <div
          class="modal-receipt__close"
          @click="hideModalReceipt"
        >
          X
        </div>
      </div>
      <validation-observer ref="valXmlToMod">
        <b-form
          enctype="multipart/form-data"
          @submit.prevent="saveXmlToMod"
        >
          <b-row
            v-if="showModal"
            class="my-4 px-2"
          >
            <div class="col-12 custom-card-efide mb-2">
              <h2> Importe neto y fecha de pago </h2>
              <b-row class="col-12 my-2">
                <!-- originalAmount -->
                <b-col
                  md="6"
                >
                  <b-form-group
                    label="Importe neto"
                    label-for="originalAmount"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Importe neto a pagar"
                      :rules="`required|isMaxVal:${xmlToMod.payingAmount}`"
                      vid="originalAmount"
                    >
                      <b-form-input
                        id="originalAmount"
                        v-model="xmlToMod.originalAmount"
                        type="number"
                        step="0.01"
                        :max="xmlToMod.payingAmount"
                        :state="errors.length > 0 ? false:null"
                        name="originalAmount"
                        :readonly="xmlToMod.payingAmount===''"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                >
                  <b-form-group
                    label="Fecha de pago"
                    label-for="payingDate"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Fecha de pago"
                      rules="required"
                      vid="payingDate"
                    >
                      <!-- <b-form-datepicker
                        id="payingDate"
                        v-model="xmlToMod.payingDate"
                        placeholder="dd/mm/aaaa"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        locale="es"
                        name="payingDate"
                        :state="errors.length > 0 ? false:null"
                        required
                      /> -->
                      <flat-pickr
                        id="payingDate"
                        v-model="xmlToMod.payingDate"
                        placeholder="dd/mm/aaaa"
                        name="payingDate"
                        :state="errors.length > 0 ? false:null"
                        :config="{ ...configDatePickr, static: true, minDate: xmlToMod.payingDateAux }"
                        required
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="col-12 custom-card-efide my-2">
              <h2> Cuotas </h2>
              <b-row class="col-12 my-2">
                <b-col
                  md="6"
                >
                  <b-form-group
                    label="Selecciona la cantidad de cuotas"
                    label-for="duesQuantity"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Cuotas"
                      rules="required"
                      vid="duesQuantity"
                    >
                      <b-form-select
                        id="duesQuantity"
                        v-model="xmlToMod.duesQuantity"
                        :options="optionsDuesQuantity"
                        :state="errors.length > 0 ? false:null"
                        name="duesQuantity"
                        :disabled="!xmlToMod.flagModDues"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-for="(values, index) in xmlToMod.payingValues"
                :key="index"
                class="col-12 my-1"
              >
                <!-- payingDate -->
                <b-col
                  md="6"
                >
                  <b-form-group
                    :label="`Fecha de pago N° ${index + 1}`"
                    :label-for="`payingDate-${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Fecha de pago N° ${index + 1}`"
                      rules="required"
                      :vid="`payingDate-${index}`"
                    >
                      <!-- <b-form-datepicker
                        :id="`payingDate-${index}`"
                        v-model="values.payingDate"
                        placeholder="dd/mm/aaaa"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        locale="es"
                        :disabled="disabledDatePickerFilter(index, xmlToMod)"
                        :min="minDatePickerFilter(index, xmlToMod)"
                        :max="maxDatePickerFilter(index, xmlToMod)"
                        :name="`payingDate-${index}`"
                        :state="errors.length > 0 ? false:null"
                        required
                      /> -->
                      <flat-pickr
                        :id="`payingDate-${index}`"
                        v-model="values.payingDate"
                        placeholder="dd/mm/aaaa"
                        :name="`payingDate-${index}`"
                        :state="errors.length > 0 ? false:null"
                        :disabled="disabledDatePickerFilter(index, xmlToMod)"
                        :config="{
                          ...configDatePickr,
                          minDate: minDatePickerFilter(index, xmlToMod),
                          maxDate: maxDatePickerFilter(index, xmlToMod),
                          static: true,
                        }"
                        required
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- payingAmount -->
                <b-col
                  md="6"
                >
                  <b-form-group
                    :label="`Importe de pago N° ${index + 1}`"
                    :label-for="`payingAmount-${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Importe de pago N° ${index + 1}`"
                      :rules="`required|money|${maxValPayingAmount(index, xmlToMod)}|min_value:0`"
                      :vid="`payingAmount-${index}`"
                    >
                      <b-form-input
                        :id="`payingAmount-${index}`"
                        v-model="values.payingAmount"
                        :state="errors.length > 0 ? false:null"
                        :name="`payingAmount-${index}`"
                        :formatter="formatterMoney"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div
              class="d-flex justify-content-center col-12 mt-2"
            >
              <b-col
                md="5"
              >
                <b-button
                  variant="secondary"
                  type="button"
                  block
                  @click="hideModalReceipt"
                >
                  Cancelar
                </b-button>
              </b-col>
              <b-col
                md="5"
              >
                <b-button
                  variant="primary"
                  type="submit"
                  block
                >
                  Guardar
                </b-button>
              </b-col>
            </div>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- form -->
  </b-col>
</template>

<script>
/* eslint-disable global-require */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { BSpinner } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/* eslint-disable */
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
/* eslint-enable */

import { VueGoodTable } from 'vue-good-table'
import UploadDropImages from '../components/UploadDropFiles.vue'

const numeral = require('numeral')

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    UploadDropImages,
    VueGoodTable,
    BSpinner,
    flatPickr,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    return {
      processing: false,
      today,
      required,
      payers: [],
      xmls: [],
      definitiveXmls: [],
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      userRuc: JSON.parse(localStorage.getItem('userData')).ruc,
      documentType: '01',
      optionsDocumentType: [
        { value: '01', text: 'Factura electrónica (XML)' },
        { value: '02', text: 'Letra electrónica' },
      ],

      dir: false,
      columns: [
        {
          label: 'RUC',
          field: 'ruc',
        },
        {
          label: 'Número de documento',
          field: 'documentNumber',
        },
        {
          label: 'Moneda',
          field: 'currency',
        },
        {
          label: 'Importe total',
          field: 'payingAmount',
          tdClass: 'text-left',
          thClass: 'text-left',
          type: 'number',
        },
        {
          label: 'Cuotas',
          field: 'duesData',
        },
        {
          label: 'Importe neto',
          field: 'originalAmount',
          tdClass: 'text-left',
          thClass: 'text-left',
          type: 'number',
        },
        {
          label: 'Fecha de Pago',
          field: 'payingDate',
        },
        {
          label: '',
          field: 'actions',
          tdClass: 'text-center',
          width: '160px',
        },
      ],
      xmlRows: [],
      pageLength: 15,
      showModal: false,
      xmlToMod: null,
      xmlIndexFlag: -1,
      optionsDuesQuantity: [
        { value: null, text: 'Por favor seleccionar una frecuencia', disabled: true },
        { value: '1', text: 'Al vencimiento' },
        { value: '2', text: '2 Cuotas' },
        { value: '3', text: '3 Cuotas' },
        { value: '4', text: '4 Cuotas' },
        { value: '5', text: '5 Cuotas' },
        { value: '6', text: '6 Cuotas' },
        { value: '7', text: '7 Cuotas' },
        { value: '8', text: '8 Cuotas' },
        { value: '9', text: '9 Cuotas' },
        { value: '10', text: '10 Cuotas' },
      ],
      // Configs DatePickr
      configDatePickr: {
        allowInput: true,
        altInput: true,
        locale: Spanish,
        altFormat: 'd/m/Y',
      },
      optionsPayingCompanies: [
        { value: null, text: 'Por favor seleccionar una empresa pagadora', disabled: true },
      ],
      optionsCurrency: [
        { value: null, text: 'Por favor seleccionar una moneda', disabled: true },
        { value: 'Soles', text: 'Soles (S/)' },
        { value: 'Dolares', text: 'Dólares ($)' },
      ],
      singleReceipt: {
        payingCompany: null,
        ruc: '',
        currency: null,
        originalAmount: '',
        payingDate: '',
        frontalImage: null,
        observation: '',
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    xmlToMod: {
      handler() {
        if (this.xmlToMod) {
          const rest = this.xmlToMod.duesQuantity - this.xmlToMod.payingValues.length
          const temp = JSON.parse(JSON.stringify(this.xmlToMod))
          if (rest > 0) {
            for (let index = 0; index < rest; index += 1) {
              temp.payingValues.push(
                {
                  payingDate: '',
                  payingAmount: '',
                },
              )
            }
            this.xmlToMod = { ...temp }
          } else if (rest < 0) {
            temp.payingValues = temp.payingValues.slice(0, rest)
            this.xmlToMod = { ...temp }
          }
          // Poner el valor de fecha de pago general en la ultima cuota
          if (this.xmlToMod.payingValues[this.xmlToMod.payingValues.length - 1].payingDate !== this.xmlToMod.payingDate) {
            this.xmlToMod.payingValues[this.xmlToMod.payingValues.length - 1].payingDate = this.xmlToMod.payingDate
          }
        }
      },
      deep: true,
    },
    singleReceipt: {
      handler() {
        if (this.singleReceipt.payingCompany) {
          const resp = this.payers.find(payer => payer._id === this.singleReceipt.payingCompany)
          this.singleReceipt.ruc = resp ? resp.ruc : ''
          this.singleReceipt.payingCompany = resp ? resp._id : ''
          this.singleReceipt.idPayer = resp.efideData ? resp.efideData.IdPagador : ''
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getPayers()
  },
  methods: {
    async getPayers() {
      const { data } = await useJwt.getMyPayers({ email: this.userEmail, ruc: this.userRuc })
      this.payers = data.payers.filter(payer => (payer.status === 'approved'))
      this.optionsPayingCompanies = [...this.optionsPayingCompanies, ...this.payers.map(payer => ({
        value: payer._id,
        text: payer.businessName,
      }))]
    },
    selectedFileDZ(files) {
      this.xmls = files
    },
    async uploadXML() {
      try {
        const formData = new FormData()

        this.xmls.forEach(xml => {
          formData.append('xmls', xml)
        })

        const { data } = await useJwt.uploadXMLToGetData(formData)
        console.log(data)

        // Xmls que se enviaran a registro
        this.definitiveXmls = [...this.xmls]
        this.$refs.uploadDI.reset() // Se eliminan los archivos del input

        const tempArray = []
        this.xmlRows = []

        // let flagAlertXML = false // Para saber si habran xmls que no pasaran el filtro

        data.receiptsInfo.forEach(receipt => {
          const find = this.payers.find(payer => payer.ruc === receipt.ruc)
          // const notExpired = receipt.paymentType === 'Contado' || new Date() <= new Date(receipt.payingDate)

          // if (find && notExpired) {
          if (find) {
            const temp = { ...receipt }
            // Payer Data
            temp.payingCompany = find._id
            temp.idPayer = find.efideData ? find.efideData.IdPagador : ''
            // Receipt Data
            temp.payingValues = []
            temp.originalAmount = temp.netAmountPayable // Monto Neto (Deberia ser al reves)
            temp.duesQuantity = receipt.duesData.length > 0 ? receipt.duesData.length : 1
            // Dues
            if (receipt.paymentType === 'Credito') {
              for (let i = 0; i < receipt.duesData.length; i += 1) {
                temp.payingValues.push(
                  {
                    payingDate: receipt.duesData[i].dueDate,
                    payingAmount: receipt.duesData[i].amount,
                  },
                )
              }
            }
            // Fecha de pago Aux
            temp.payingDateAux = receipt.payingDate

            // DIFERENCIA DE DIAS ENTRE RECEPTIONDATE Y PAYINGDATE (SI ES MENOR A 8 o SI ES AL CONTADO SE PUEDEN EDITAR LAS CUOTAS)
            let flagModDues = false
            if (receipt.paymentType === 'Contado' || receipt.payingDate === '') {
              temp.payingDateAux = new Date() // Para obligarlo a que ponga una fecha mayor o igual a hoy (debido a que no hay fecha de pago en al contado)
              flagModDues = true
            } else {
              const diff = this.getDifferenceBetweenDates(receipt.receptionDate, receipt.payingDate)
              flagModDues = diff <= 8
            }

            temp.flagModDues = flagModDues

            tempArray.push(temp)
          } else {
            const index = this.definitiveXmls.map(xml => xml.name).indexOf(receipt.originalname)
            this.definitiveXmls.splice(index, 1)
            // flagAlertXML = true
            // eslint-disable-next-line
            // const textAlert = find ? (notExpired ? '' : `La factura ${receipt.documentNumber} ha expirado.`) : `La factura ${receipt.documentNumber} no tiene a la pagadora registrada.`
            // if (textAlert !== '') this.toastAlert('Advertencia', 'warning', textAlert)
            this.toastAlert('Advertencia', 'warning', `La factura ${receipt.documentNumber} no tiene a la pagadora registrada.`)
          }
        })

        /* if (flagAlertXML) {
          this.toastAlert('Advertencia', 'warning', 'No todas las facturas que subiste tienen la pagadora registrada')
        } */

        this.xmlRows = [...tempArray]
      } catch (error) {
        this.toastAlert('Error', 'danger', 'El formato del documento es incorrecto')
      }
    },
    async createReceipts() {
      this.processing = true
      const flag = this.xmlRows.some(xml => (parseInt(xml.duesQuantity, 10) !== xml.payingValues.length))
      if (flag) {
        this.processing = false
        this.toastAlert('Error', 'danger', 'Debes completar los datos de cada factura')
        return
      }

      const formData = new FormData()
      formData.append('ruc', this.userRuc)
      formData.append('email', this.userEmail)
      formData.append('createdAt', new Date())
      for (let index = 0; index < this.xmlRows.length; index += 1) {
        formData.append('payerId[]', this.xmlRows[index].payingCompany)
        formData.append('payerIdEfide[]', this.xmlRows[index].idPayer)
        formData.append('xml', this.definitiveXmls[index])
        formData.append('documentType[]', this.xmlRows[index].documentType)
        formData.append('documentNumber[]', this.xmlRows[index].documentNumber)
        formData.append('receptionDate[]', this.xmlRows[index].receptionDate)
        formData.append('payingDate[]', this.xmlRows[index].payingDate)
        formData.append('currency[]', this.xmlRows[index].currency)
        formData.append('payingAmount[]', this.xmlRows[index].payingAmount)
        formData.append('payingTerm[]', this.calculatePayingTerm(this.xmlRows[index].payingValues))
        formData.append('duesQuantity[]', this.xmlRows[index].duesQuantity)
        formData.append('originalAmount[]', this.xmlRows[index].originalAmount)
        formData.append('payingValues[]', JSON.stringify(this.xmlRows[index].payingValues))
      }
      try {
        const resp = await useJwt.registerClientReceipt(formData)
        if (resp) {
          this.processing = false
          this.$router.replace('/cliente/lista-solicitudes-por-enviar')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Éxito',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Se ha registrado el comprobante',
                },
              },
              {
                timeout: 8000,
              })
            })
        }
      } catch (error) {
        this.processing = false
        if (error.response.data.error.receiptError !== '') {
          this.toastAlert('Error al registrar solicitud de factoring', 'danger', error.response.data.error.receiptError)
        }
        console.log('errores', error)
      }
    },
    showReceiptModal(documentNumber) {
      const index = this.xmlRows.map(xml => xml.documentNumber).indexOf(documentNumber)
      this.xmlIndexFlag = index
      this.xmlToMod = JSON.parse(JSON.stringify(this.xmlRows[index]))
      this.showModal = true
    },
    hideModalReceipt() {
      this.xmlIndexFlag = -1
      this.$refs['modal-receipt'].hide()
    },
    formatterMoney(value) {
      return value.replace(/[^0-9.]/g, '')
    },
    disabledDatePickerFilter(index, xmlToMod) {
      if (index + 1 === xmlToMod.payingValues.length) {
        return true
      }
      return (index !== 0 && xmlToMod.payingValues[index - 1] !== undefined && xmlToMod.payingValues[index - 1].payingDate === '')
    },
    minDatePickerFilter(index, xmlToMod) {
      if (xmlToMod.paymentType === 'Credito') {
        if (xmlToMod.duesData[index]) {
          return xmlToMod.duesData[index].dueDate
        }
        if (this.xmlToMod.payingValues[index - 1] === undefined) {
          return this.today
        }
        return xmlToMod.payingValues[index - 1].payingDate
        // return xmlToMod.duesData[index] ? xmlToMod.duesData[index].dueDate : this.today
      }
      if (index === 0) {
        return this.today
      }
      return (this.xmlToMod.payingValues[index - 1] === undefined ? this.today : xmlToMod.payingValues[index - 1].payingDate)
    },
    maxDatePickerFilter(index, xmlToMod) {
      if (index + 1 < xmlToMod.payingValues.length) {
        return xmlToMod.payingValues[index + 1].payingDate
      }
      return ''
    },
    maxValPayingAmount(index, xmlToMod) {
      if (xmlToMod.paymentType === 'Credito' && xmlToMod.duesData[index]) {
        return `isMaxVal:${xmlToMod.duesData[index].amount}`
      }
      return ''
    },
    saveXmlToMod() {
      this.$refs.valXmlToMod.validate().then(success => {
        if (success) {
          let sum = numeral(0)
          this.xmlToMod.payingValues.forEach(due => {
            sum = sum.add(due.payingAmount)
          })
          // eslint-disable-next-line no-underscore-dangle
          if (sum._value !== parseFloat(this.xmlToMod.originalAmount)) {
            this.toastAlert('Error al guardar', 'danger', 'La suma de los importes de pago de las cuotas debe ser igual al importe neto a pagar')
            return
          }

          this.xmlRows[this.xmlIndexFlag] = JSON.parse(JSON.stringify(this.xmlToMod))
          // Por alguna razon la tabla no se modificaba (Por eso lo de abajo)
          const temp = this.xmlRows
          this.xmlRows = []
          setTimeout(() => { this.xmlRows = temp }, 100)
          this.hideModalReceipt()
          this.toastAlert('Éxito', 'success', 'Se han guardado correctamente los datos')
        }
      })
    },
    deleteXML(documentNumber, originalname) {
      // Delete data table
      const index = this.xmlRows.map(xml => xml.documentNumber).indexOf(documentNumber)
      this.xmlRows.splice(index, 1)
      // Delete file
      const indexFile = this.definitiveXmls.map(xml => xml.name).indexOf(originalname)
      this.definitiveXmls.splice(indexFile, 1)
    },
    toastAlert(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon: 'CoffeeIcon',
          variant,
          text,
        },
      },
      {
        timeout: 8000,
      })
    },
    onFrontalImageChange(e) {
      const selectedFile = e.target.files[0]
      if (selectedFile && selectedFile.type.startsWith('image') === false && selectedFile.type.startsWith('application/pdf') === false) {
        this.toastAlert('No está permitido', 'danger', "Solo puede subir imágenes y pdf's")
        document.getElementById('frontalImage').value = null
        return
      }
      this.singleReceipt.frontalImage = selectedFile
    },
    async registerSingleReceipt() {
      this.$refs.valSingleReceipt.validate().then(async success => {
        if (success) {
          const payingValues = [{
            payingDate: this.singleReceipt.payingDate,
            payingAmount: this.singleReceipt.originalAmount,
          }]
          const formData = new FormData()
          const today = new Date()
          formData.append('email', this.userEmail)
          formData.append('ruc', this.userRuc)
          formData.append('payerId[]', this.singleReceipt.payingCompany)
          formData.append('payerIdEfide[]', this.singleReceipt.idPayer)
          formData.append('payingDate[]', this.singleReceipt.payingDate)
          formData.append('currency[]', this.singleReceipt.currency)
          formData.append('payingTerm[]', this.calculatePayingTerm(payingValues))
          formData.append('duesQuantity[]', 1)
          formData.append('originalAmount[]', this.singleReceipt.originalAmount)
          formData.append('payingValues[]', JSON.stringify(payingValues))
          formData.append('xml', this.singleReceipt.frontalImage)
          formData.append('observation[]', this.singleReceipt.observation)
          formData.append('createdAt', today)
          formData.append('documentType[]', 'Letra de cambio')
          formData.append('documentNumber[]', '')
          formData.append('receptionDate[]', today)
          formData.append('payingAmount[]', this.singleReceipt.originalAmount)

          try {
            const resp = await useJwt.registerClientReceipt(formData)
            console.log(resp)
            if (resp) {
              this.$router.replace('/cliente/lista-solicitudes-por-enviar')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Se ha registrado el comprobante',
                    },
                  },
                  {
                    timeout: 8000,
                  })
                })
            }
          } catch (error) {
            if (error.response.data.error.receiptError !== '') {
              this.toastAlert('Error al registrar solicitud de factoring', 'danger', error.response.data.error.receiptError)
            }
            console.log('errores', error)
          }
        }
      })
    },
    calculatePayingTerm(payingValues) {
      let val = 0
      for (let i = 0; i < payingValues.length; i += 1) {
        const days = Math.ceil((new Date(payingValues[i].payingDate) - new Date()) / (1000 * 3600 * 24))
        if (days > val) val = days
      }
      return val
    },
    getDifferenceBetweenDates(startDate, endDate) {
      const stDate = new Date(startDate).getTime()
      const ndDate = new Date(endDate).getTime()
      return (ndDate - stDate) / (1000 * 60 * 60 * 24)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
