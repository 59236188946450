<template>
  <div class="file__content">
    <img
      v-if="type.toLowerCase() == 'pdf'"
      src="@/assets/images/files/pdf.svg"
      width="30"
      height="50"
      class="mr-1"
    >
    <img
      v-else-if="type.toLowerCase() == 'doc' || type == 'docx'"
      src="@/assets/images/files/word.svg"
      width="30"
      height="30"
      class="mr-1"
    >
    <img
      v-else-if="type.toLowerCase() == 'jpg'"
      src="@/assets/images/files/jpg.svg"
      width="30"
      height="50"
      class="mr-1"
    >
    <img
      v-else-if="type.toLowerCase() == 'png'"
      src="@/assets/images/files/png.svg"
      width="30"
      height="35"
      class="mr-1"
    >
    <img
      v-else-if="type.toLowerCase() == 'mp4'"
      src="@/assets/images/files/mp4.svg"
      width="30"
      height="35"
      class="mr-1"
    >
    <img
      v-else-if="type.toLowerCase() == 'mov'"
      src="@/assets/images/files/mov.svg"
      width="30"
      height="35"
      class="mr-1"
    >
    <img
      v-else-if="type.toLowerCase() == 'avi'"
      src="@/assets/images/files/avi.svg"
      width="30"
      height="35"
      class="mr-1"
    >
    <img
      v-else-if="type.toLowerCase() == 'xml'"
      src="@/assets/images/files/xml.svg"
      width="30"
      height="35"
      class="mr-1"
    >
    <img
      v-else
      src="@/assets/images/files/pdf.svg"
      width="30"
      height="50"
      class="mr-1"
    >
    <div class="file__text">
      <span>{{ file.title || "Documento" }}</span>
    </div>
    <div class="file__scope">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'FileBox',
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      type: '',
    }
  },
  created() {
    const context = this.file.title.split('.')
    this.type = context[context.length - 1]
  },
}
</script>

<style scoped>
.file__content {
  background-color: #ffffff;
  border: 1px solid #d5d5de;
  border-radius: 4px;
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: center;
  /* flex-wrap: wrap; */
  justify-content: space-between;
}

.file__text {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
}

.file__scope {
  padding: 0 8px;
}

.file__content span {
  color: #706f94;
  font-weight: 600;
  overflow-wrap: anywhere;
}

@media screen and (max-width: 490px) {
  .file__text {
    margin: 8px 0;
  }

  .file__content {
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
