<template>
  <div
    class="container-drop"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="drop($event)"
  >
    <div
      v-show="dropped == 2"
      class="drop"
    />
    <!-- Error Message -->
    <div
      v-show="error"
      class="error mt-1 mb-3"
    >
      {{ error }}
    </div>

    <!-- To inform user how to upload file -->
    <div
      v-show="files.length == 0"
      class="beforeUpload my-5"
    >
      <input
        ref="uploadInput"
        type="file"
        style="z-index: 1"
        :accept="fileType"
        multiple
        @change="previewFiles"
      >
      <!-- Image Placeholder -->
      <!-- <img
        class="m-0"
        src="@/assets/images/logo/logo.png"
        alt="upload-image-icon"
      /> -->
      <p class="mainMessage my-2">
        {{ uploadMsg ? uploadMsg : "Click to upload or drop your images here" }}
      </p>
      <p class="otherMessage my-1">
        {{ otherMsg ? otherMsg : "" }}
      </p>
    </div>
    <div v-show="files.length > 0">
      <div class="d-flex justify-content-center row">
        <!-- <button type="button" class="clearButton" @click="reset"> -->
        <button
          v-if="files.length > 1"
          type="button"
          class="clearButton"
          @click="reset"
        >
          {{ clearAll ? clearAll : "clear All" }}
        </button>
        <div
          v-for="(file, i) in files"
          :key="i"
          class="filePreview__fileHolder"
        >
          <FileBox
            :file="{ title: file.name }"
          >
            <img
              class="filePreview__delete"
              src="@/assets/images/files/trash.svg"
              alt="delete-icon"
              @click="deleteFile(i)"
            >
          </FileBox>
          <!-- <p>{{i}}</p> -->
        </div>
        <div class="d-flex justify-content-center mt-3 col-12">
          <div
            v-if="files.length < max"
            class="filePreview__plus"
            @click="append"
          >
            +
          </div>
        </div>
        <p class="otherMessage mt-2 mb-1">
          {{ otherMsg ? otherMsg : "" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import FileBox from './FileBox.vue'

export default {
  name: "VueUploadImages", // vue component name
  components: {
    FileBox,
  },
  data() {
    return {
      error: '',
      files: [],
      dropped: 0,
    }
  },
  props: {
    max: Number,
    uploadMsg: String,
    otherMsg: String,
    maxError: String,
    fileError: String,
    clearAll: String,
    fileType: String,
  },
  watch: {
    files() {
      if (this.files.length < this.$props.max) {
        this.error = ""
      }
    },
  },
  methods: {
    dragOver() {
      this.dropped = 2
    },
    dragLeave() {},
    drop(e) {
      let status = true
      let files = Array.from(e.dataTransfer.files)
      if (e && files) {
        files.forEach((file) => {
          if (file.type.startsWith('text/xml') === false) status = false
          // if (file.type.startsWith('image') === false) status = false
        });
        if (status == true) {
          if (
            this.$props.max &&
            files.length + this.files.length > this.$props.max
          ) {
            this.error = this.$props.maxError
              ? this.$props.maxError
              : `Maximum files is` + this.$props.max
          } else {
            this.files.push(...files)
            this.previewFiles()
          }
        } else {
          this.error = this.$props.fileError
            ? this.$props.fileError
            : `Unsupported file type`
        }
      }
      this.dropped = 0
    },
    append() {
      this.$refs.uploadInput.click()
    },
    deleteFile(index) {
      this.files.splice(index, 1)
      this.$emit('changed', this.files)
      this.$refs.uploadInput.value = null
    },
    previewFiles(event) {
      // Evitar elementos repetidos
      let flag = false
      event.currentTarget.files.forEach(file => {
        if (this.files.find(f => f.name === file.name)) flag = true
      })

      if (flag) {
        this.$refs.uploadInput.value = null
        return
      }

      // Contar archivos
      if (
        this.$props.max &&
        event &&
        event.currentTarget.files.length + this.files.length > this.$props.max
      ) {
        this.error = this.$props.maxError
          ? this.$props.maxError
          : `Maximum files is` + this.$props.max
        return
      }
      if (this.dropped == 0) this.files.push(...event.currentTarget.files)
      this.error = ''
      this.$emit('changed', this.files)
    },
    reset() {
      this.$refs.uploadInput.value = null
      this.files = []
      this.$emit('changed', this.files)
    },
  },
}
/* eslint-enable */
</script>

<style scoped>
/* .container {
  width: 100%;
  height: 100%;
  background: #f7fafc;
  border: 0.5px solid #a3a8b1;
  border-radius: 10px;
  padding: 30px;
  position: relative;
} */

.container-drop {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 30px;
  position: relative;
  background-image: repeating-linear-gradient(1deg, #23569c, #23569c 14px, transparent 14px, transparent 25px, #23569c 25px), repeating-linear-gradient(91deg, #23569c, #23569c 14px, transparent 14px, transparent 25px, #23569c 25px), repeating-linear-gradient(181deg, #23569c, #23569c 14px, transparent 14px, transparent 25px, #23569c 25px), repeating-linear-gradient(271deg, #23569c, #23569c 14px, transparent 14px, transparent 25px, #23569c 25px);
  background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  height: fit-content;
}

.drop {
  width: 100%;
  height: 100%;
  top: 0;
  /* border-radius: 10px; */
  position: absolute;
  background-color: #f4f6ff;
  left: 0;
  border: 3px dashed #a3a8b1;
}

.error {
  text-align: center;
  color: #eb5656;
  font-size: 1.1rem;
  font-weight: 700;
}

.beforeUpload {
  position: relative;
  text-align: center;
}

.beforeUpload input {
  width: 100%;
  margin: auto;
  height: 100%;
  opacity: 0;
  position: absolute;
  background: red;
  display: block;
}

.beforeUpload input:hover {
  cursor: pointer;
}

.beforeUpload .icon {
  width: 150px;
  margin: auto;
  display: block;
}

.filePreview__fileHolder {
  background: #fff;
  position: relative;
  border-radius: 10px;
  margin: 10px 5px;
  display: inline-block;
}

.filePreview__delete {
  display: flex;
  width: 29px;
  height: 29px;
}

.filePreview__delete:hover {
  cursor: pointer;
}

.filePreview__plus {
  color: #2d3748;
  background: #f7fafc;
  border-radius: 50%;
  font-size: 21pt;
  height: 30px;
  width: 30px;
  text-align: center;
  border: 1px dashed;
  line-height: 27px;
}

.filePreview__plus:hover {
  cursor: pointer;
}

.clearButton {
  color: #616f87;
  position: absolute;
  font-weight: 700;
  top: 8px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
}

.mainMessage {
  color: #23539C;
  font-size: 21px;
  font-weight: 700;
}

.otherMessage {
  color: #23539C;
  font-size: 14px;
  font-weight: 500;
}
</style>
